<template>
  <div class="vh-100 d-flex justify-content-center align-items-center">
    <div class="wrapper">
      <div class="title">Salesperson</div>
      <form >
        <div class="field mt-5">
          <input type="text" v-model="data.username" />
          <label>Username</label>
        </div>
        <div class="field mt-5">
          <input type="password" v-model="data.password" />
          <label>Password</label>
        </div>

        <div class="field mt-5">
          <input type="button"  @click="submit" value="Login" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import swal from "sweetalert2";
export default {
  name: "Login",
  data() {
    return {
      alertusername: false,
      alertpassword: false,
      data: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    submit() {
      if (this.data.username == "") {
        swal.fire({
          icon: "warning",
          title: "Login",
          text: "ยังไม่ได้ใส่ Username",
        });
        return;
      }
      if (this.data.password == "") {
        swal.fire({
          icon: "warning",
          title: "Login",
          text: "ยังไม่ได้ใส่ Password",
        });
        return;
      }
      var params = new URLSearchParams();

      params.append("Username", this.data.username);
      params.append("Password", this.data.password);

      this.$http({
        method: "post",
        url: "api/saleman/SaleManLogin",
        params: params,
      })
        .then((response) => {
          if (response.status == "200") {
            window.localStorage.setItem("token", response.data.token);
            window.localStorage.setItem("customerid", response.data.customerId);
            this.$cookies.set("refreshToken", response.data.refreshToken, "1h");
            window.localStorage.setItem("saleCode", response.data.saleCode);

            window.localStorage.setItem("dbId", response.data.saleName.dbId);
            this.$cookies.set("authen", true, "1h");
            let date = new Date();
            window.localStorage.setItem("date", date);
            window.localStorage.setItem("saleName",response.data.saleName.saleName)

            this.$router.push("/starter");
          } else if (response.status == "204") {
            swal.fire({
              icon: "error",
              title: "Login",
              text: "ไม่พบข้อชื่อผู้ใช้งาน",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response != undefined) {
            if (error.response.status == "500") {
              swal.fire({
                icon: "error",
                title: "Login",
                text: error.response.data,
              });
            }
          } else {
            // alert("error");
            swal.fire({
              icon: "error",
              title: "Login",
              text: error,
            });
          }
        });
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.error : false;
    },
  },
  validations: {
    data: {
      username: { required },
      password: { required },
    },
  },
};
</script>

<style scoped lang="css">
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
html,
body {
  display: grid;
  height: 100%;
  width: 100%;
  place-items: center;
  background: #f2f2f2;
  /* background: linear-gradient(-135deg, #c850c0, #4158d0); */
}
::selection {
  background: #4158d0;
  color: #fff;
}
.wrapper {
  width: 380px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
}
.wrapper .title {
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  line-height: 100px;
  color: #fff;
  user-select: none;
  border-radius: 15px 15px 0 0;
  background: linear-gradient(-135deg, #c850c0, #4158d0);
}
.wrapper form {
  padding: 10px 30px 50px 30px;
}
.wrapper form .field {
  height: 50px;
  width: 100%;
  margin-top: 20px;
  position: relative;
}
.wrapper form .field input {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 17px;
  padding-left: 20px;
  border: 1px solid lightgrey;
  border-radius: 25px;
  transition: all 0.3s ease;
}
.wrapper form .field input:focus,
form .field input:valid {
  border-color: #4158d0;
}
.wrapper form .field label {
  position: absolute;
  top: 50%;
  left: 20px;
  color: #999999;
  font-weight: 400;
  font-size: 17px;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}
form .field input:focus ~ label,
form .field input:valid ~ label {
  top: 0%;
  font-size: 16px;
  color: #4158d0;
  background: #fff;
  transform: translateY(-50%);
}
form .content {
  display: flex;
  width: 100%;
  height: 50px;
  font-size: 16px;
  align-items: center;
  justify-content: space-around;
}
form .content .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
form .content input {
  width: 15px;
  height: 15px;
  background: red;
}
form .content label {
  color: #262626;
  user-select: none;
  padding-left: 5px;
}
form .content .pass-link {
  color: "";
}
form .field input[type="button"] {
  color: #fff;
  border: none;
  padding-left: 0;
  margin-top: -10px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  background: linear-gradient(-135deg, #c850c0, #4158d0);
  transition: all 0.3s ease;
}
form .field input[type="button"]:active {
  transform: scale(0.95);
}
form .signup-link {
  color: #262626;
  margin-top: 20px;
  text-align: center;
}
form .pass-link a,
form .signup-link a {
  color: #4158d0;
  text-decoration: none;
}
form .pass-link a:hover,
form .signup-link a:hover {
  text-decoration: underline;
}
</style>
